import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Particles from "react-particles-js"
import Header from "../components/header"

const Hero = () => (
  <section className="container hero bg-dark-main flex-center-col">
    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
      <Particles height="100%" />
    </div>
    <div style={{ zIndex: 2 }} className="flex-center-col">
      <h1
        className="mt-5 mb-3 p-2 text-rainbow monospace"
        style={{ lineHeight: "10rem", fontWeight: "bold" }}
      >
        404 Not Found
      </h1>
      <p className="text-white sans" style={{ fontSize: "1.5rem" }}>
        เสียใจด้วย เราไม่พบหน้าที่คุณเรียก
      </p>
    </div>
  </section>
)
const NotfoundPage = () => (
  <Layout>
    <SEO title="THACO | Not Found" name="404" />
    <Header page="404" />
    <Hero />
  </Layout>
)

export default NotfoundPage
